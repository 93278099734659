#timer {
    position: relative;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2rem;
    padding-right: 8vw;
    gap: 1rem;
}

#timer-dots {
    width: 1rem;
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    margin-bottom: 0;
    gap: 2rem;
}

#timer-dots div {
    width: 15px;
    height: 15px;
    background-color: white;
}

.timer-card {
    position: relative;
    width: 7rem;
    height: 10rem;
    font-size: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #191824;
}

.timer-card.label:after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 15px;
    margin: 5px;
    font-size: .8rem;
    color: white;
    text-align: right;
}


.timer-card.label.one:after {
    content: attr(data-label);
}

.timer-card.label.two:after {
    content: attr(data-label);
}

@media screen and (max-width:1050px) {
    #timer-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #timer-container h3 {
        padding: 0;
        text-align: center;
    }

    #timer {
        padding: 0;
        display: flex;
        justify-content: center;
    }
}


@media screen and (max-width:750px) {
    #main-title {
        font-size: 2rem;
    }

    #main-title2 {
        font-size: 4.5vw;
        letter-spacing: 1vw;
    }

    #main-btn {
        font-size: .8em;
        padding-inline: 1.5em;
        padding-block: .5rem;
    }

    #section-h1 {
        font-size: 1.5rem;
    }

    #roulette {
        width: 55vw;
    }

    section article h2 {
        font-size: 1rem;
    }

    .timer-card {
        width: 5rem;
        height: 7rem;
        font-size: 4rem;
    }

    #timer-dots {
        width: 1rem;
        height: 6rem;
        gap: 1rem;
    }

    .timer-card.label:after {
        font-size: .6rem;
        margin-right: 5px;
        margin-bottom: 1px;
    }

    #footer {
        padding-inline: 8vw;
    }

    #footer p {
        padding: 0 !important;
        font-size: .8rem;
    }
}

@media screen and (max-width:450px) {
    main article h1 {
        font-size: 1.5rem;
    }

    main article h2 {
        font-size: .9rem;
        letter-spacing: 5px;
    }

    #timer {
        gap: .5rem;
    }

    .timer-card {
        width: 3.5rem;
        height: 5.5rem;
        font-size: 3rem;
    }

    #timer-dots {
        width: 1rem;
        height: 4rem;
        gap: .5rem;
    }

    #timer-dots div {
        width: 10px;
        height: 10px;
    }

    .timer-card.label:after {
        font-size: .5rem;
        margin-right: 3px;
        margin-bottom: 0;
    }

    #footer {
        display: flex;
        flex-direction: column !important;
    }

    #footer p {
        margin-bottom: 1.5rem;
    }
}