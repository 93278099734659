@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: "Lovelo";
  src: url("./content/fonts/Lovelo-Black.ttf");
}

:root {
  --bg-color: #141414;
  --purple: #8c52ff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Lovelo, 'Arial', sans-serif;
}

body, html {
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  overflow-x: hidden;
}